/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiCompany,
    ApiCompanyFromJSON,
    ApiCompanyToJSON,
    ApiUpdateCompanyRequest,
    ApiUpdateCompanyRequestFromJSON,
    ApiUpdateCompanyRequestToJSON,
} from '../models';

export interface GetCompanyRequest {
    companyId: string;
}

export interface UpdateCompanyRequest {
    companyId: string;
    apiUpdateCompanyRequest: ApiUpdateCompanyRequest;
}

/**
 * 
 */
export class CompaniesApi extends runtime.BaseAPI {

    /**
     * returns all the information related to the company (list of funds, LPs, legal data)
     */
    async getCompanyRaw(requestParameters: GetCompanyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiCompany>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling getCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiCompanyFromJSON(jsonValue));
    }

    /**
     * returns all the information related to the company (list of funds, LPs, legal data)
     */
    async getCompany(requestParameters: GetCompanyRequest, initOverrides?: RequestInit): Promise<ApiCompany> {
        const response = await this.getCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * returns list of companies and funds
     */
    async listCompaniesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiCompany>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiCompanyFromJSON));
    }

    /**
     * returns list of companies and funds
     */
    async listCompanies(initOverrides?: RequestInit): Promise<Array<ApiCompany>> {
        const response = await this.listCompaniesRaw(initOverrides);
        return await response.value();
    }

    /**
     * updates the company
     */
    async updateCompanyRaw(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiCompany>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling updateCompany.');
        }

        if (requestParameters.apiUpdateCompanyRequest === null || requestParameters.apiUpdateCompanyRequest === undefined) {
            throw new runtime.RequiredError('apiUpdateCompanyRequest','Required parameter requestParameters.apiUpdateCompanyRequest was null or undefined when calling updateCompany.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/companies/{companyId}`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiUpdateCompanyRequestToJSON(requestParameters.apiUpdateCompanyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiCompanyFromJSON(jsonValue));
    }

    /**
     * updates the company
     */
    async updateCompany(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit): Promise<ApiCompany> {
        const response = await this.updateCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
