/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiCompany,
    ApiCompanyFromJSON,
    ApiCompanyToJSON,
    ApiFund,
    ApiFundFromJSON,
    ApiFundToJSON,
    ApiLimitedPartnerInvestment,
    ApiLimitedPartnerInvestmentFromJSON,
    ApiLimitedPartnerInvestmentToJSON,
    ApiUpsertFundRequest,
    ApiUpsertFundRequestFromJSON,
    ApiUpsertFundRequestToJSON,
} from '../models';

export interface CreateFundRequest {
    id: string;
    apiUpsertFundRequest: ApiUpsertFundRequest;
}

export interface GetFundRequest {
    fundId: string;
}

export interface UpdateFundRequest {
    fundId: string;
    apiUpsertFundRequest: ApiUpsertFundRequest;
}

/**
 * 
 */
export class FundsApi extends runtime.BaseAPI {

    /**
     * creates a fund for a company
     */
    async createFundRaw(requestParameters: CreateFundRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiCompany>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createFund.');
        }

        if (requestParameters.apiUpsertFundRequest === null || requestParameters.apiUpsertFundRequest === undefined) {
            throw new runtime.RequiredError('apiUpsertFundRequest','Required parameter requestParameters.apiUpsertFundRequest was null or undefined when calling createFund.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/companies/{id}/funds`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiUpsertFundRequestToJSON(requestParameters.apiUpsertFundRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiCompanyFromJSON(jsonValue));
    }

    /**
     * creates a fund for a company
     */
    async createFund(requestParameters: CreateFundRequest, initOverrides?: RequestInit): Promise<ApiCompany> {
        const response = await this.createFundRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * gets a fund
     */
    async getFundRaw(requestParameters: GetFundRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiFund>> {
        if (requestParameters.fundId === null || requestParameters.fundId === undefined) {
            throw new runtime.RequiredError('fundId','Required parameter requestParameters.fundId was null or undefined when calling getFund.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/funds/{fundId}`.replace(`{${"fundId"}}`, encodeURIComponent(String(requestParameters.fundId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiFundFromJSON(jsonValue));
    }

    /**
     * gets a fund
     */
    async getFund(requestParameters: GetFundRequest, initOverrides?: RequestInit): Promise<ApiFund> {
        const response = await this.getFundRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets investments for an LP
     */
    async getLimitedPartnerInvestmentsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ApiLimitedPartnerInvestment>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/limited-partner/investments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiLimitedPartnerInvestmentFromJSON));
    }

    /**
     * Gets investments for an LP
     */
    async getLimitedPartnerInvestments(initOverrides?: RequestInit): Promise<Array<ApiLimitedPartnerInvestment>> {
        const response = await this.getLimitedPartnerInvestmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * updates a fund
     */
    async updateFundRaw(requestParameters: UpdateFundRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiFund>> {
        if (requestParameters.fundId === null || requestParameters.fundId === undefined) {
            throw new runtime.RequiredError('fundId','Required parameter requestParameters.fundId was null or undefined when calling updateFund.');
        }

        if (requestParameters.apiUpsertFundRequest === null || requestParameters.apiUpsertFundRequest === undefined) {
            throw new runtime.RequiredError('apiUpsertFundRequest','Required parameter requestParameters.apiUpsertFundRequest was null or undefined when calling updateFund.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/funds/{fundId}`.replace(`{${"fundId"}}`, encodeURIComponent(String(requestParameters.fundId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiUpsertFundRequestToJSON(requestParameters.apiUpsertFundRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiFundFromJSON(jsonValue));
    }

    /**
     * updates a fund
     */
    async updateFund(requestParameters: UpdateFundRequest, initOverrides?: RequestInit): Promise<ApiFund> {
        const response = await this.updateFundRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
