/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InstrumentType,
    InstrumentTypeFromJSON,
    InstrumentTypeFromJSONTyped,
    InstrumentTypeToJSON,
} from './';

/**
 * investment object
 * @export
 * @interface ApiInvestment
 */
export interface ApiInvestment {
    /**
     * 
     * @type {string}
     * @memberof ApiInvestment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestment
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ApiInvestment
     */
    fairValue?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiInvestment
     */
    sector: string;
    /**
     * 
     * @type {InstrumentType}
     * @memberof ApiInvestment
     */
    instrument: InstrumentType;
}

export function ApiInvestmentFromJSON(json: any): ApiInvestment {
    return ApiInvestmentFromJSONTyped(json, false);
}

export function ApiInvestmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiInvestment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'fairValue': !exists(json, 'fairValue') ? undefined : json['fairValue'],
        'sector': json['sector'],
        'instrument': InstrumentTypeFromJSON(json['instrument']),
    };
}

export function ApiInvestmentToJSON(value?: ApiInvestment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'fairValue': value.fairValue,
        'sector': value.sector,
        'instrument': InstrumentTypeToJSON(value.instrument),
    };
}

