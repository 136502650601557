/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiVeriffStatus {
    Approved = 'APPROVED',
    Expired = 'EXPIRED',
    Declined = 'DECLINED',
    ResubmissionRequested = 'RESUBMISSION_REQUESTED',
    Abandoned = 'ABANDONED',
    InProgress = 'IN_PROGRESS'
}

export function ApiVeriffStatusFromJSON(json: any): ApiVeriffStatus {
    return ApiVeriffStatusFromJSONTyped(json, false);
}

export function ApiVeriffStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiVeriffStatus {
    return json as ApiVeriffStatus;
}

export function ApiVeriffStatusToJSON(value?: ApiVeriffStatus | null): any {
    return value as any;
}

