/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiAdmin,
    ApiAdminFromJSON,
    ApiAdminFromJSONTyped,
    ApiAdminToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiCreateAdminRequest
 */
export interface ApiCreateAdminRequest {
    /**
     * 
     * @type {ApiAdmin}
     * @memberof ApiCreateAdminRequest
     */
    adminInfo: ApiAdmin;
    /**
     * 
     * @type {string}
     * @memberof ApiCreateAdminRequest
     */
    company?: string;
}

export function ApiCreateAdminRequestFromJSON(json: any): ApiCreateAdminRequest {
    return ApiCreateAdminRequestFromJSONTyped(json, false);
}

export function ApiCreateAdminRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCreateAdminRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adminInfo': ApiAdminFromJSON(json['adminInfo']),
        'company': !exists(json, 'company') ? undefined : json['company'],
    };
}

export function ApiCreateAdminRequestToJSON(value?: ApiCreateAdminRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adminInfo': ApiAdminToJSON(value.adminInfo),
        'company': value.company,
    };
}

