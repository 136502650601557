/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface RefreshCompanyWithDemoRequest {
    companyId: string;
    body: boolean;
}

/**
 * 
 */
export class DemoApi extends runtime.BaseAPI {

    /**
     * ReFreshes a Company with Demo Data
     */
    async refreshCompanyWithDemoRaw(requestParameters: RefreshCompanyWithDemoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.companyId === null || requestParameters.companyId === undefined) {
            throw new runtime.RequiredError('companyId','Required parameter requestParameters.companyId was null or undefined when calling refreshCompanyWithDemo.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling refreshCompanyWithDemo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/owner/companies/{companyId}/demo`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters.companyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * ReFreshes a Company with Demo Data
     */
    async refreshCompanyWithDemo(requestParameters: RefreshCompanyWithDemoRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.refreshCompanyWithDemoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
