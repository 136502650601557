/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiCompany,
    ApiCompanyFromJSON,
    ApiCompanyFromJSONTyped,
    ApiCompanyToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiAdminCompany
 */
export interface ApiAdminCompany {
    /**
     * 
     * @type {string}
     * @memberof ApiAdminCompany
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAdminCompany
     */
    legalPowers?: string;
    /**
     * 
     * @type {ApiCompany}
     * @memberof ApiAdminCompany
     */
    company?: ApiCompany;
}

export function ApiAdminCompanyFromJSON(json: any): ApiAdminCompany {
    return ApiAdminCompanyFromJSONTyped(json, false);
}

export function ApiAdminCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAdminCompany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'legalPowers': !exists(json, 'legalPowers') ? undefined : json['legalPowers'],
        'company': !exists(json, 'company') ? undefined : ApiCompanyFromJSON(json['company']),
    };
}

export function ApiAdminCompanyToJSON(value?: ApiAdminCompany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'legalPowers': value.legalPowers,
        'company': ApiCompanyToJSON(value.company),
    };
}

