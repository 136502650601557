/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiInvestment,
    ApiInvestmentFromJSON,
    ApiInvestmentFromJSONTyped,
    ApiInvestmentToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApiLimitedPartnerInvestment
 */
export interface ApiLimitedPartnerInvestment {
    /**
     * 
     * @type {string}
     * @memberof ApiLimitedPartnerInvestment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiLimitedPartnerInvestment
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ApiLimitedPartnerInvestment
     */
    symbol: string;
    /**
     * 
     * @type {number}
     * @memberof ApiLimitedPartnerInvestment
     */
    ownership: number;
    /**
     * 
     * @type {number}
     * @memberof ApiLimitedPartnerInvestment
     */
    paidInCapital: number;
    /**
     * 
     * @type {number}
     * @memberof ApiLimitedPartnerInvestment
     */
    fairValue: number;
    /**
     * 
     * @type {Array<ApiInvestment>}
     * @memberof ApiLimitedPartnerInvestment
     */
    investmentBreakdown?: Array<ApiInvestment>;
}

export function ApiLimitedPartnerInvestmentFromJSON(json: any): ApiLimitedPartnerInvestment {
    return ApiLimitedPartnerInvestmentFromJSONTyped(json, false);
}

export function ApiLimitedPartnerInvestmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiLimitedPartnerInvestment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'symbol': json['symbol'],
        'ownership': json['ownership'],
        'paidInCapital': json['paidInCapital'],
        'fairValue': json['fairValue'],
        'investmentBreakdown': !exists(json, 'investmentBreakdown') ? undefined : ((json['investmentBreakdown'] as Array<any>).map(ApiInvestmentFromJSON)),
    };
}

export function ApiLimitedPartnerInvestmentToJSON(value?: ApiLimitedPartnerInvestment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'symbol': value.symbol,
        'ownership': value.ownership,
        'paidInCapital': value.paidInCapital,
        'fairValue': value.fairValue,
        'investmentBreakdown': value.investmentBreakdown === undefined ? undefined : ((value.investmentBreakdown as Array<any>).map(ApiInvestmentToJSON)),
    };
}

