/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InstrumentType {
    Equity = 'EQUITY',
    Convertible = 'CONVERTIBLE',
    Option = 'OPTION',
    Warrant = 'WARRANT',
    Loan = 'LOAN'
}

export function InstrumentTypeFromJSON(json: any): InstrumentType {
    return InstrumentTypeFromJSONTyped(json, false);
}

export function InstrumentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstrumentType {
    return json as InstrumentType;
}

export function InstrumentTypeToJSON(value?: InstrumentType | null): any {
    return value as any;
}

