/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RoleType {
    Admin = 'ADMIN',
    Owner = 'OWNER',
    LimitedPartner = 'LIMITED_PARTNER'
}

export function RoleTypeFromJSON(json: any): RoleType {
    return RoleTypeFromJSONTyped(json, false);
}

export function RoleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleType {
    return json as RoleType;
}

export function RoleTypeToJSON(value?: RoleType | null): any {
    return value as any;
}

