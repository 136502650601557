/* tslint:disable */
/* eslint-disable */
/**
 * Poolside API
 * Poolside API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LimitedPartnerType {
    NaturalPerson = 'NATURAL_PERSON',
    Company = 'COMPANY'
}

export function LimitedPartnerTypeFromJSON(json: any): LimitedPartnerType {
    return LimitedPartnerTypeFromJSONTyped(json, false);
}

export function LimitedPartnerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LimitedPartnerType {
    return json as LimitedPartnerType;
}

export function LimitedPartnerTypeToJSON(value?: LimitedPartnerType | null): any {
    return value as any;
}

